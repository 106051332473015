import React, { useState } from "react";
import Text from "./text";
import DialogWrapper from "../dialogs/dialog-wrapper";
import { FiInfo } from "react-icons/fi";

const ContentModerator = () => {
    const [open, setOpen] = useState<boolean>(false);
    return (<span>
        <span onClick={() => { setOpen(true); }} style={{ color: "#cc7722", textDecoration: "underline", cursor: "pointer" }}>[Content Moderated]</span>
        <DialogWrapper
            onClose={() => {
                setOpen(false);
            }}
            open={open}
            root={true}
            title={"Content Moderator"}
        >
            <div style={{ height:"250px",display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <FiInfo style={{ color: "grey", margin: "auto 0px" }} size={50} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Text variant={"caption"} bold style={{ marginTop: "6px", marginLeft: "16px", color: "grey", }}>
                        Sydetasker identified this as containing content that is a breach of our Community Guidelines. This could be due to including a private email address, phone or mobile numbers.
                    </Text>

                    <Text variant={"caption"} bold style={{ marginTop: "6px", marginLeft: "16px", color: "grey", }}>
                        Please note, Taskers & Posters are allowed to communicate privately in the “Make an Offer” chat. Audio Messaging is provided in the private chat if Users prefer to speak out loud about your task. Outside communication can result in your Sydetasker Account being restricted.

                    </Text>
                    {/* <Text variant={"caption"} bold style={{ marginTop: "6px", marginLeft: "16px", color: "grey", }}>
                        Once the task has been assigned, the exact task address is shown & you are free to "Private Message" the assigned Sydetasker with full task details.
                    </Text> */}
                </div>
            </div>
        </DialogWrapper>
    </span>

    );
};

export default ContentModerator;
