import React, { useEffect, useRef, useState } from "react"
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material";
import Flickity from "react-flickity-component";
import "./scrolling-tasks.css";
import TaskCard from "./task-card";
import {Link} from "gatsby";
import { isQuickTaskProject } from "../../../utils/constant.urls";

const ScrollingTasks = ({tasks, reverse = false}: {tasks: any; reverse?: boolean}) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [flickity, setFlickity] = useState<Flickity | null>(null);
  const [flickityInterval, setFlickityInterval] = useState<NodeJS.Timeout>(null);
  const progressBar = useRef<HTMLDivElement>(null);
  const progressBarBackground = useRef<HTMLDivElement>(null);
  const height = xsDown ? "130px" : "155px";

  useEffect(() => {
    if (flickity)
      flickity.on("scroll", function (progress: number) {
        if (progressBar.current && progressBarBackground.current) {
          progress = Math.max(0, Math.min(1, progress));
          progressBar.current.style.width = progress * 100 + "%";
        }
      });

    if (flickity) {
      play();
    }
  }, [flickity]);

  const play = () => {
    if (flickity && !flickityInterval) {
      let c = setInterval(marquee, 30);
      setFlickityInterval(c);
    } else {
    }
  };

  const marquee = () => {
    // @ts-ignore
    let t;
    // if (continueX)
    // t = continueX;
    // else
    t = flickity["x"];
    flickity["x"] = t + (!reverse ? 0.5 : -0.5);
    // @ts-ignore
    flickity.settle(flickity["x"]);
  };

  // const pause = () => {
  // if (flickity && flickityInterval) {
  //     flickity["x"] = continueX - 0.5;
  //     setContinueX(continueX-0.5);
  //     clearInterval(flickityInterval);
  //     // @ts-ignore
  //     flickity.settle(flickity["x"])
  // setFlickityInterval(null);
  // }
  // }

  return (
    <div
      style={{
        height: height,
        padding: "10px 0",
        width: "100%",
        boxSizing: "unset",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Flickity
        flickityRef={(el: Flickity) => setFlickity(el)}
        options={{
          draggable: false,
          friction: 0.4,
          freeScroll: true,
          prevNextButtons: false,
          pageDots: false,
          wrapAround: true,
          cellAlign: "left",
          percentPosition: true,
          setGallerySize: true,
          accessibility: true,
          resize: true,
        }}
        reloadOnUpdate
        disableImagesLoaded
      >
        {tasks.map((t, index) => {
          return (
            <>
              {isQuickTaskProject ? (
                <div
                  key={index}
                  style={{
                    height: height,
                    width: "330px",
                    marginRight: "24px",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                  }}
                >
                  <TaskCard task={t} key={index} />
                </div>
              ) : (
                <div
                  key={t.id}
                  style={{
                    height: height,
                    width: "330px",
                    marginRight: "24px",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                  }}
                >
                  <Link
                    to={`/tasks/${t.id}`}
                    style={{
                      height: height,
                      width: "330px",
                      marginRight: "24px",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                    }}
                  >
                    <TaskCard task={t} />
                  </Link>
                </div>
              )}
            </>
          );
        })}
      </Flickity>
    </div>
  );
};

export default ScrollingTasks;