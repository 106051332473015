import CleaningImage from "../images/categories/cleaning.png";
import AssemblyImage from "../images/categories/assembly.png";
import HandymanImage from "../images/categories/handyman.png";
import DeliveryImage from "../images/categories/delivery.png";
import GardeningImage from "../images/categories/gardening.png";
import AdminImage from "../images/categories/admin.png";
import MoversImage from "../images/categories/removalists.png";
import ComputerImage from "../images/categories/computer.png";
import CameraImage from "../images/categories/photography.png";
import OthersImage from "../images/categories/custom.png";
import PainterImage from "../images/categories/painting.png";
import LiftingImage from "../images/categories/lifting.png";

import QuickCleaningImg from "../quickTaskAssets/category_Icons/cleaning-service.png";
import QuickDogWalkingImg from "../quickTaskAssets/category_Icons/dog-walking.png";
import QuickElectricianImg from "../quickTaskAssets/category_Icons/electrician.png";
import QuickGamerImg from "../quickTaskAssets/category_Icons/gamer.png";
import QuickGardenerImg from "../quickTaskAssets/category_Icons/gardener.png";
import QuickPaintImg from "../quickTaskAssets/category_Icons/painter.png";
import QuickmotherImg from "../quickTaskAssets/category_Icons/mother.png";
import QuickplumberImg from "../quickTaskAssets/category_Icons/plumber.png";
import QuickDeliveryImg from "../quickTaskAssets/category_Icons/driver.png";
import QuickElectricityImg from "../quickTaskAssets/category_Icons/electricity2.png";
import QuickRemovalsImg from "../quickTaskAssets/category_Icons/wholesale.png";
import QuickHandymanImg from "../quickTaskAssets/category_Icons/handyman.png";
import QuickAnyThing from "../quickTaskAssets/category_Icons/antThing_lightbulb.png";

import QuickDrywallerBackImg from "../quickTaskAssets/catergoryImages/Drywaller.png";
import QuickFemaleworkerBackImg from "../quickTaskAssets/catergoryImages/Femaleworker.png";
import QuickmannobackgroundBackImg from "../quickTaskAssets/catergoryImages/mannobackground.png";
import QuickPaintBackImg from "../quickTaskAssets/catergoryImages/Femaleworker2.png";
import QuickFemaleworker2BackImg from "../quickTaskAssets/catergoryImages/Youngworker.png";
import QuickManPaintingBackImg from "../quickTaskAssets/catergoryImages/ManPainting.png";
import QuicExcitedmanBackImg from "../quickTaskAssets/catergoryImages/Excitedman.png";
import QuicRemovalBackImg from "../quickTaskAssets/catergoryImages/removals.jpg";
import QtCustom from "../quickTaskAssets/catergoryImages/Drywaller.png";
import Assembly from "../images/category_photos/assembly.jpeg";
import Painting from "../images/category_photos/painting.jpeg";
import Hauling from "../images/category_photos/hauling.jpg";
import Delivery from "../images/category_photos/delivery.jpg";
import SnowRemoval from "../images/category_photos/snow_removal.jpg";
import Cleaning from "../images/category_photos/cleaning.jpg";
import Tutoring from "../images/category_photos/tutoring.jpg";
import Computer from "../images/category_photos/computer_it.jpg";
import HeavyLifting from "../images/category_photos/lifting.jpg";
import Yardwork from "../images/category_photos/yardwork.jpg";
import Handyman from "../images/category_photos/handyman.jpeg";
import Admin from "../images/category_photos/admin.jpg";
import Custom from "../images/category_photos/custom.jpg";

import Photography from "../images/category_photos/photography.jpg";
import {isQuickTaskProject} from "../utils/constant.urls";
export const getCategoryImage = (category: string) => {
  if (category)
    if (!isQuickTaskProject) {
      switch (category.toLowerCase()) {
        case "cleaning":
          return CleaningImage;
        case "assembly":
          return AssemblyImage;
        case "handyman":
          return HandymanImage;
        case "delivery":
          return DeliveryImage;
        case "yardwork":
          return GardeningImage;
        case "admin":
          return AdminImage;
        case "hauling":
          return MoversImage;
        case "computer it":
        case "computer-it":
        case "computerit":
          return ComputerImage;
        case "photography":
          return CameraImage;
        case "others":
          return OthersImage;
        case "painting":
          return PainterImage;
        case "lifting":
          return LiftingImage;
        default:
          return OthersImage;
      }
    } else {
      switch (category.toLowerCase()) {
        case "cleaning and ironing services":
        case "cleaning-and-ironing-services":
          return QuickCleaningImg;
        case "painting & decorating":
        case "painting-&-decorating":
          return QuickPaintImg;
        case "IT and Technical Support":
        case "IT-and-Technical-Support":
        case "ITandTechnicalSupport":
          return QuickGamerImg;
        case "personal assistance & babysitting":
        case "personal-assistance-&-babysitting":
          return QuickmotherImg;
        case "Removals":
        case "removals":
          return QuickRemovalsImg;
        case "home repairs and maintenance":
        case "home-repairs-and-maintenance":
          return QuickplumberImg;
        case "dog walking and pet care":
        case "dog-walking-and-pet-care":
          return QuickDogWalkingImg;
        case "handyman":
        case "handy man":
        case "handy-man":
          return QuickGamerImg;
        case "landscaping & gardening":
        case "landscaping-&-gardening":
          return QuickGardenerImg;
        case "electrical repairs":
        case "ElectricalRepairs":
        case "Electrical-Repairs":
          return QuickElectricityImg;
        case "delivery driver":
        case "deliverydriver":
        case "delivery-driver":
          return QuickDeliveryImg;
        case "others":
          return QuickAnyThing;
        default:
          return QuickAnyThing;
      }
    }
  else return isQuickTaskProject ? QuickAnyThing : OthersImage;
};

export const categories = (): string[] => {
  if (isQuickTaskProject) {
    return [
      "others",
      "cleaning And Ironing Services",
      "Personal Assistance & Babysitting",
      "IT and Technical Support",
      "home repairs and maintenance",
      "Landscaping & Gardening",
      "Handy Man",
      "Dog Walking And Pet Care",
      "Painting & Decorating",
      "Removals",
      "Electrical Repairs",
      "Delivery Drive",
      "others",
    ];
  } else {
    return [
      "cleaning",
      "assembly",
      "handyman",
      "delivery",
      "yardwork",
      "hauling",
      "admin",
      "computer it",
      "photography",
      "lifting",
      "painting",
      "others",
    ];
  }
};

export const homeCategories = (): string[] => {
  if (isQuickTaskProject) {
    return [
      "Cleaning And Ironing Services",
      "personal Assistance & Babysitting",
      "IT and Technical Support",
      "Home Repairs and Maintenance",
      "Landscaping & Gardening",
      "Handy Man",
      "Dog Walking And Pet Care",
      "Painting & Decorating",
      "Removals",
      "Electrical Repairs",
      "Delivery Drive",
    ];
  } else {
    return ["cleaning", "photography", "admin", "yardwork", "assembly", "hauling", "delivery"];
  }
};

export const getImagePosition = (category: string): string => {
  switch (category) {
    case "photography":
    case "cleaning":
      return "left center";
    case "assembly":
    case "yardwork":
    case "admin":
    case "hauling":
      return "35% center";
    case "delivery":
      return "30% center";
    default:
      return "left center";
  }
};

export const getCategoryLink = (category: string) => {
  if (category)
    if (!isQuickTaskProject) {
      switch (category.toLowerCase()) {
        case "admin":
        case "cleaning":
        case "assembly":
        case "handyman":
        case "delivery":
        case "painting":
        case "hauling":
        case "photography":
          return category.toLowerCase();
        case "lifting":
          return "heavy-lifting";
        case "yardwork":
          return "yard-work";
        case "computer it":
          return "computer-it";
        case "snow-removal":
          return "snow-removal";
        case "custom":
        case "Custom":
          return "custom";
        case "others":
          return "custom";
      }
    } else {
      switch (category.toLowerCase()) {
        case "cleaning and ironing services":
        case "cleaning-and-ironing-services":
          return "Cleaning-And-Ironing-Services";
        case "painting & decorating":
        case "painting-&-decorating":
          return "painting-&-decorating";
        case "IT and Technical Support":
        case "it and technical support":
        case "IT-and-Technical-Support":
        case "ITandTechnicalSupport":
          return "IT-and-Technical-Support";
        case "personal assistance & babysitting":
        case "personal-assistance-&-babysitting":
          return "Personal-Assistance-&-Babysitting";
        case "Removals":
        case "removals":
          return "Removals";
        case "home repairs and maintenance":
        case "home-repairs-and-maintenance":
          return "Home-Repairs-And-Maintenance";
        case "dog walking and pet care":
        case "dog-walking-and-pet-care":
          return "Dog-Walking-And-Pet-Care";
        case "handyman":
        case "handy-man":
        case "handy man":
          return "Handy-Man";
        case "landscaping & gardening":
        case "landscaping-&-gardening":
          return "Landscaping&-Gardening";
        case "Electrical Repairs":
        case "electrical repairs":
        case "electrical-repairs":
        case "ElectricalRepairs":
          return "Electrical-Repairs";
        case "delivery driver":
        case "deliverydriver":
        case "delivery-driver":
          return "Delivery-Driver";
        case "anything-In-between":
        case "anything in between":
          return "Anything-In-Between";
        case "others":
          return "Anything-In-Between";
      }
    }
  else return isQuickTaskProject ? QuickAnyThing : OthersImage;
};

export const getImage = (location: string) => {
  if (!isQuickTaskProject) {
    switch (location) {
      case "assembly":
        return Assembly;
      case "handyman":
        return Handyman;
      case "painting":
        return Painting;
      case "hauling":
        return Hauling;
      case "delivery":
        return Delivery;
      case "snow-removal":
        return SnowRemoval;
      case "cleaning":
        return Cleaning;
      case "tutoring":
        return Tutoring;
      case "computer-it":
        return Computer;
      case "heavy-lifting":
        return HeavyLifting;
      case "yardwork":
      case "yard-work":
        return Yardwork;
      case "admin":
        return Admin;
      case "custom":
        return Custom;
      case "photography":
        return Photography;
      default:
        return Handyman;
    }
  } else {
    switch (location) {
      case "cleaning and ironing services":
      case "cleaning-and-ironing-services":
        return QuickmannobackgroundBackImg;
      case "painting & decorating":
      case "painting-&-decorating":
        return QuickPaintBackImg;
      case "IT and Technical Support":
      case "IT-and-Technical-Support":
      case "ITandTechnicalSupport":
        return QuickmannobackgroundBackImg;
      case "Personal Assistance & babysitting":
      case "Personal-Assistance-&-babysitting":
        return QuicExcitedmanBackImg;
      case "Removals":
      case "removals":
        return QuicRemovalBackImg;
      case "home repairs and maintenance":
      case "home-repairs-and-maintenance":
        return QuickPaintBackImg;
      case "dog walking and pet care":
      case "dog-walking-and-pet-care":
        return QuickmannobackgroundBackImg;
      case "Handyman":
      case "handyman":
      case "Handy Man":
      case "HandyMan":
      case "handyMan":
      case "handy-man":
        return QuickmannobackgroundBackImg;
      case "landscaping & gardening":
      case "landscaping-&-gardening":
        return QuickDrywallerBackImg;
      case "Electrical-Repairs":
        return QuicExcitedmanBackImg;
      case "delivery drive":
        return QuickDeliveryImg;
      case "AnythingInBetween":
      case "Anything-In Between":
      case "Anything-In-Between":
        return QtCustom;
      default:
        return QuickmannobackgroundBackImg;
    }
  }
};